<template>
  <a-modal
    :width="950"
    :visible="visible"
    :confirm-loading="loading"
    title="审批信息"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
  >
    <template #footer>
      <a-space>
        <a-button
          type="danger"
          v-if="data.approveState === 0"
          @click="refuseApply"
          >驳回</a-button
        >
        <a-button
          type="primary"
          v-if="data.approveState === 0"
          @click="agreeApply"
          >通过</a-button
        >
      </a-space>
    </template>
    <a-form
      ref="form"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col
          :md="12"
          :sm="24"
          :xs="24"
          v-for="(item, index) in formData"
          :key="index"
        >
          <a-form-item
            :label="item.label"
            v-if="
              [
                '标签',
                '单行文本',
                '多行文本',
                '数字',
                '手机号',
                '单项选择',
                '多项选择',
                '日期',
                '时间',
                '下拉框',
                '身份证号',
                '地理位置',
                '省市区'
              ].includes(item.type)
            "
          >
            <div>
              {{ item.rules.value }}
            </div>
          </a-form-item>
          <a-form-item
            :label="item.label"
            v-if="
              ['单张图片', '多张图片', '健康码', '涂写签名'].includes(item.type)
            "
          >
            <div v-for="(imgUrl, imgIndex) in item.rules.value" :key="imgIndex">
              <div class="image">
                <a-image
                  :width="120"
                  :height="120"
                  :src="imgUrl.url + '?width=120'"
                  alt=""
                  :preview="imgUrl"
                />
              </div>
            </div>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24">
          <a-form-item label="状态">
            <div>
              {{ data.approveState === 0 ? '审核中' : '' }}
              {{ data.approveState === 1 ? '已通过' : '' }}
              {{ data.approveState === 2 ? '已驳回' : '' }}
              {{ data.approveState === 3 ? '已过期' : '' }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24" :xs="24" v-if="data.approveMessage">
          <a-form-item label="审批意见">
            <div>{{ data.approveMessage }}</div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 驳回理由弹出层 -->
    <a-modal
      title="驳回"
      :width="500"
      v-model:visible="showRejectForm"
      @ok="rejectOk"
      @cancel="rejectCancel"
    >
      <a-form :model="rejectForm" ref="rejectForm">
        <a-form-item label="审批意见" name="approveMessage" required>
          <a-textarea
            v-model:value="rejectForm.approveMessage"
            :rows="4"
            placeholder="请输入审批意见"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 通过理由弹出层 -->
    <a-modal
      title="通过"
      :width="500"
      v-model:visible="showAgreeForm"
      @ok="agreeOk"
      @cancel="showAgreeForm = false"
    >
      <a-form :model="agreeForm">
        <a-form-item label="审批意见" name="approveMessage">
          <a-textarea
            v-model:value="agreeForm.approveMessage"
            :rows="4"
            placeholder="请输入审批意见"
          ></a-textarea>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-modal>
</template>

<script>
import * as corpUserApi from '@/api/corp/corpUser.js'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
export default {
  props: {
    visible: Boolean,
    data: Object
  },
  data() {
    return {
      loading: false,
      formData: [],
      corpUserApply: {},
      showRejectForm: false,
      showAgreeForm: false,
      rejectForm: {
        approveMessage: ''
      },
      agreeForm: {
        approveMessage: ''
      }
    }
  },
  watch: {
    data() {
      if (this.data) {
        console.log('data',this.data)
        this.getFormData()
      }
    }
  },
  methods: {
    refuseApply() {
      const that = this
      this.showRejectForm = true
      // Modal.confirm({
      //   title: '驳回申请?',
      //   icon: createVNode(ExclamationCircleOutlined),
      //   content: '是否确认驳回该申请',
      //   onOk() {
      //     // corpUserApi
      //     //   .refuse(that.corpUserApply)
      //     //   .then((res) => {
      //     //     if (res.code === 0) {
      //     //       that.$emit('done')
      //     //       that.updateVisible(false)
      //     //     } else {
      //     //       that.$message.error(res.msg)
      //     //     }
      //     //   })
      //     //   .catch((e) => {
      //     //     that.$message.error(e.message)
      //     //   })
      //   },

      //   onCancel() {}
      // })
    },
    agreeApply() {
      this.showAgreeForm = true
     
    },
    rejectOk() {
      const that = this
      this.$refs.rejectForm.validate().then(() => {
        const data = Object.assign({}, this.corpUserApply, this.rejectForm)
        corpUserApi
          .refuse(data)
          .then((res) => {
            if (res.code === 0) {
              this.showRejectForm = false
              that.$emit('done')
              that.updateVisible(false)
            } else {
              that.$message.error(res.msg)
            }
          })
          .catch((e) => {
            that.$message.error(e.message)
          })
      })
    },
    rejectCancel() {
      this.showRejectForm = false
    },
    agreeOk() {
      const data = Object.assign({}, this.corpUserApply, this.agreeForm)
      corpUserApi
        .agree(data)
        .then((res) => {
          if (res.code === 0) {
            this.$emit('done')
            this.showAgreeForm = false
            this.updateVisible(false)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    getFormData() {
      corpUserApi
        .infoFormData({
          userId: this.data.applicantId,
          corpId: this.data.corpId
        })
        .then((res) => {
          if (res.code === 0) {
            // this.formData = res.data.formData
            this.formData = res.data.applyFormData
            this.corpUserApply = res.data.corpUserApply
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.image {
  width: 120px;
  height: 120px;
}
.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
